import React, { lazy, Suspense, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import { Route, Switch, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import ApplBar from "./AppBar";
import AppDrawer from "./AppDrawer";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  parent: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    display: "flex",
  },
}));

const Login = lazy(() => import("./logAccount/logAccount"));
const FormApp = lazy(() => import("./mainApp/formApp"));
const UpdateSoft = lazy(() => import("./mainApp/UpdateSoft"));
const FormAdd = lazy(() => import("./addSlots/formAdd"));
const Company = lazy(() => import("./mainApp/Company"));
const Requests = lazy(() => import("./mainApp/RequestTable"));
const Account = lazy(() => import("./mainApp/Account"));
const Settings = lazy(() => import("./mainApp/Settings"));
const UploadXls = lazy(() => import("./mainApp/UploadXls"));
const Reports = lazy(() => import("./mainApp/Reports"));
const FormCodes = lazy(() => import("./mainApp/FormCodes"));
const FormCodesList = lazy(() => import("./mainApp/FormCodesList"));
const FormReports = lazy(() => import("./mainApp/FormReports"));
const BlockedSlots = lazy(() => import("./mainApp/BlockedSlots"));
const PersonalFormCodesList = lazy(() =>
  import("./mainApp/PersonalFormCodesList")
);

const Main = () => {
  const location = useLocation();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const loadingComponent = (
    <>
      <div className={classes.parent}>
        <CircularProgress></CircularProgress>
      </div>
    </>
  );

  if (location.pathname === "/") {
    return (
      <Suspense fallback={loadingComponent}>
        <Route exact path="/" component={Login} />
      </Suspense>
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ApplBar doOpen={setMobileOpen} />
      <AppDrawer open={mobileOpen} doOpen={setMobileOpen} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Suspense fallback={loadingComponent}>
          <Switch>
            <Route path="/form" component={FormApp} />
            <Route path="/codes_form" component={FormCodes} />
            <Route exact path="/update" component={UpdateSoft} />
            <Route exact path="/add" component={FormAdd} />
            <Route exact path="/company" component={Company} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/requests" component={Requests} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/upload" component={UploadXls} />
            <Route exact path="/reports" component={Reports} />
            <Route exact path="/codes_form_list" component={FormCodesList} />
            <Route exact path="/form_reports" component={FormReports} />
            <Route exact path="/blocked_slots" component={BlockedSlots} />
            <Route
              exact
              path="/personal_codes_form_list"
              component={PersonalFormCodesList}
            />
          </Switch>
        </Suspense>
      </main>
    </div>
  );
};

export default Main;
