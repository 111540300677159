import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation, Redirect } from "react-router-dom";
import { Hidden } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  clicked: {
    color: "black",
    marginLeft: "15%",
    fontSize: "18px",
    fontWeight: 500,
    borderLeft: "5px solid black",
    padding: "10% 0% 5% 10%",
  },
  unClicked: {
    margin: "10% 0% 5% 15%",
    fontSize: "18px",
    fontWeight: 500,
    color: "#000",
  },
}));

export function hasPermission(role, minimumRole) {
  const roles = ["client", "admin", "supervisor", "ceo"];
  if (!roles.includes(role) || !roles.includes(minimumRole)) {
    return null;
  }
  return roles.indexOf(role) >= roles.indexOf(minimumRole);
}

const AppDrawer = (props) => {
  const location = useLocation();
  const classes = useStyles();

  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [redirectToLogin, shouldRedirectToLogin] = useState(false);

  const see_personal_forms = ["devops@kodingtech.com"];

  useEffect(() => {
    const userINFO = JSON.parse(localStorage.getItem("egt.user"));
    if (!userINFO) {
      shouldRedirectToLogin(true);
    } else {
      setRole(userINFO.role);
      setEmail(userINFO.username);
    }
  }, []);

  const deleteTokens = useCallback(() => {
    localStorage.removeItem("egt.user");
    localStorage.removeItem("egt.authToken");
  }, []);

  const handleDrawerToggle = () => {
    props.doOpen(!props.open);
  };

  if (redirectToLogin) {
    return <Redirect to="/"></Redirect>;
  }

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const drawer = (
    <>
      {" "}
      <div className={classes.toolbar} />
      <List style={{ marginTop: "50%" }}>
        {
          <Link to="/codes_form" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Form codes"
                disableTypography
                className={
                  location.pathname === "/codes_form"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        }
        {
          <Link to="/codes_form_list" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="List form codes"
                disableTypography
                className={
                  location.pathname === "/codes_form_list"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        }
        {see_personal_forms.includes(email) && (
          <Link
            to="/personal_codes_form_list"
            style={{ textDecoration: "none" }}
          >
            <ListItem button disableRipple>
              <ListItemText
                primary="List personal form codes"
                disableTypography
                className={
                  location.pathname === "/personal_codes_form_list"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        )}
        {
          <Link to="/form_reports" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Form reports"
                disableTypography
                className={
                  location.pathname === "/form_reports"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        }
        <Link to="/form" style={{ textDecoration: "none" }}>
          <ListItem button disableRipple>
            <ListItemText
              primary="Table"
              disableTypography
              className={
                location.pathname === "/form"
                  ? classes.clicked
                  : classes.unClicked
              }
            />
          </ListItem>
        </Link>
        {hasPermission(role, "admin") ? (
          <Link to="/update" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Update Soft"
                disableTypography
                className={
                  location.pathname === "/update"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "supervisor") ? (
          <Link to="/company" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Company"
                disableTypography
                className={
                  location.pathname === "/company"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "admin") ? (
          <Link to="/add" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Add Slot Machine"
                disableTypography
                className={
                  location.pathname === "/add"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "supervisor") ? (
          <Link to="/blocked_slots" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Control slot machines"
                disableTypography
                className={
                  location.pathname === "/blocked_slots"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "admin") ? (
          <Link to="/account" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Account"
                disableTypography
                className={
                  location.pathname === "/account"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "admin") ? (
          <Link to="/requests" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Log"
                disableTypography
                className={
                  location.pathname === "/requests"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "supervisor") ? (
          <Link to="/upload" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Bulk update"
                disableTypography
                className={
                  location.pathname === "/upload"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        {hasPermission(role, "admin") ? (
          <Link to="/reports" style={{ textDecoration: "none" }}>
            <ListItem button disableRipple>
              <ListItemText
                primary="Statistics"
                disableTypography
                className={
                  location.pathname === "/reports"
                    ? classes.clicked
                    : classes.unClicked
                }
              />
            </ListItem>
          </Link>
        ) : null}
        <Link to="/" style={{ textDecoration: "none" }} onClick={deleteTokens}>
          <ListItem button disableRipple>
            <ListItemText
              primary="Log out"
              disableTypography
              style={{
                margin: "10% 0% 5% 15%",
                fontSize: "18px",
                fontWeight: 500,
                color: "#000",
              }}
            />
          </ListItem>
        </Link>
      </List>
    </>
  );

  return (
    <>
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          className={classes.drawer}
          open={props.open}
          onClose={handleDrawerToggle}
          variant={"temporary"}
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant={"permanent"}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
};

export default AppDrawer;
